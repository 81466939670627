.SubscriptionDescription__main-container{
    /* border: 3px solid red; */
}
    .SubscriptionDescription__inner-img-container{
        /* border: 3px solid blue; */
    }
        .SubscriptionDescription__img{
            /* border: 2px solid green; */
        }                 

    .SubscriptionDescription__title-container{
        /* border: 3px solid blue */
    }
    .SubscriptionDescription__title-container p{
        color: var(--secundary-color);
        font-family: "Roboto Mono", sans-serif;
        font-size: 3rem;
        font-style: normal;
        font-weight: 600;
        line-height: 3.75rem; /* 125% */ 
    }
    .SubscriptionDescription__description-container{
        /* border: 3px solid red; */
        margin-top: 1em;
    }
    .SubscriptionDescription__description-container p{
        color: #00030A;
        font-family: Lato, sans-serif;
        font-size: 1.5rem;
        font-style: normal; 
        font-weight: 300;
        line-height: 2.25rem;
    }



@media (max-width: 575.98px) {
    .SubscriptionDescription__main-container{
        /* border: 3px solid blue; */
        width: 100%;
        padding: 0;
    }
    .SubscriptionDescription__img-container{
        /* border: 3px solid green; */
    }
        .SubscriptionDescription__inner-img-container{
            /* border: 3px solid rgb(0, 0, 0); */
            max-width: 30em;
            min-width: 16em;
        }
            .SubscriptionDescription__img{
                width: 100%;
                /* border: 3px solid red; */
            }
    .SubscriptionDescription__title-container p {
        font-size: 2rem;
        line-height: 2.5rem; 
    }
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .SubscriptionDescription__main-container{
        /* border: 3px solid blue; */
        width: 100%;
        padding: 0;
    }
    .SubscriptionDescription__img-container{
        /* border: 3px solid green; */
    }
        .SubscriptionDescription__inner-img-container{
            /* border: 3px solid rgb(0, 0, 0); */
            max-width: 40em;
            min-width: 16em;
        }
            .SubscriptionDescription__img{
                width: 100%;
                /* border: 3px solid red; */
            }
    .SubscriptionDescription__title-container p {
        font-size: 2.5rem;
        line-height: 2.5rem; 
    }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .SubscriptionDescription__main-container{
        /* border: 3px solid blue; */
        width: 100%;
        padding: 0;
    }
    .SubscriptionDescription__img-container{
        /* border: 3px solid green; */
    }
        .SubscriptionDescription__inner-img-container{
            /* border: 3px solid rgb(0, 0, 0); */
            max-width: 30em;
            min-width: 16em;
        }
            .SubscriptionDescription__img{
                width: 100%;
                /* border: 3px solid red; */
            }
    .SubscriptionDescription__title-container p {
        font-size: 2rem;
        line-height: 2.5rem; 
    }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .SubscriptionDescription__main-container{
        /* border: 3px solid blue; */
        width: 100%;
        padding: 0;
    }
    .SubscriptionDescription__img-container{
        /* border: 3px solid rgb(0, 0, 0); */
    }
        .SubscriptionDescription__inner-img-container{
            /* border: 3px solid rgb(0, 0, 0); */
            max-width: 30em;
            min-width: 16em;
        }
            .SubscriptionDescription__img{
                width: 100%;
                /* border: 3px solid red; */
            }
    .SubscriptionDescription__title-container p {
        font-size: 2rem;
        line-height: 2.5rem; 
    }

}

@media (min-width: 1200px) {
    .SubscriptionDescription__main-container{
        /* border: 3px solid rgb(0, 0, 0); */
        max-width: 80%;
        padding: 0;
    }
    .SubscriptionDescription__img-container{
        /* border: 3px solid green; */
    }
        .SubscriptionDescription__inner-img-container{
            /* border: 3px solid rgb(0, 0, 0); */
            max-width: 100%;
            min-width: 16em;
        }
            .SubscriptionDescription__img{
                width: 100%;
                /* border: 3px solid red; */
            }
    .SubscriptionDescription__title-container p {
        font-size: 2rem;
        line-height: 2.5rem; 
    }
}