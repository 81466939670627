
.SectionInfo__main-section-container{
    padding-left: 2em;
    padding-right: 2em;
    margin-top: 1.5em;
    margin-bottom: 2em;
    /* border: 3px solid green; */
}
    .SectionInfo__title-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 1.25rem;
        padding: 3.125rem 6rem;
    }
        .SectionInfo__title{
            color: var(--secundary-color);
            text-align: center;
            font-family: 'Karantina', sans-serif;
            font-size: 5rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.05rem; /* 35% */
        }


@media (max-width: 575.98px) {
    .SectionInfo__main-section-container{
        margin: 0;
        padding: 0;
    }
    .SectionInfo__title{
        text-align: center;
        font-size: 2.3rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2.5rem;
    }
    .SectionInfo__title-container{
        padding: .9em;
        border-radius: 0;
    }
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .SectionInfo__title{
        line-height: 3.9rem;
    }
    .SectionInfo__title-container{
        padding: .9em;

    }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .SectionInfo__title{
        line-height: 3.8rem;
    }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .SectionInfo__title{
        line-height: 2.5rem;
    }

}

@media (min-width: 1200px) {
    .SectionInfo__title{
        line-height: 2.5rem;
    }
}
        