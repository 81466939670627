.BenefitCard__main-container{
    /* border: 5px solid blue; */
    /* max-width: 28em; */
    border-radius: 1.25rem;
    background: #f5f1fd;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-top: 2rem;
    max-width: 26.6875rem;
    max-height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1.25rem;
}
    .BenefitCard__inner-container{
        /* border: 3px solid saddlebrown; */
    }
        .BenefitCard__header-container{
            /* border: 8px solid green; */
            width: 100%;
        }
            .BenefitCard__media-container{
                /* border: 3px solid rgb(0, 0, 0); */
            }
                .BenefitCard__icon-container{
                    /* border: 3px solid blue; */
                }
                .BenefitCard__description-container{
                    /* border: 3px solid red; */
                }   

.BenefitCard__divider{
    margin-top: .5em;
    border: 2px solid var(--quinary-color);
}

/* CARD TITLE*/
.BenefitCard__description-container div p{
    color: var(--quinary-color);
    font-family: 'Roboto Mono', sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
}

/*CARD BODY*/
.BenefitCard__benefit p{
    color: #00030A;
    font-family: Lato;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.25rem; /* 150% */
}

.BenefitCard__information{
    color: #00030A;
    font-family: Lato;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 300;
    line-height: 2.25rem;
}





/* =============== RESPONSIVE =========*/

@media (max-width: 575.98px) {
    .BenefitCard__main-container{
        /* border: 5px solid blue; */
        max-width: 25em;
        border-radius: 1.25rem;
        background: #f5f1fd;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: .5em;
    }    
    .BenefitCard__inner-container{
        padding: 1em;
    }
    .BenefitCard__media-container{
        display: flex;
        flex-direction: row;
    }
    .BenefitCard__description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* border: 5px solid rgb(0, 0, 0); */
    }

    .BenefitCard__about-benefit{
        padding: 1em;
    }
    .BenefitCard__benefit p {
        text-align: justify;
        font-size: 1.2rem;
        line-height: 1.5rem;
    }
    .BenefitCard__about-information{
        padding: 1em;
        /* border: 3px solid red; */
    }
    .BenefitCard__information p {
        text-align: justify;
        font-size: 1.4rem;
        line-height: 1.9rem;
    }

    .BenefitCard__description-container div p{
        font-size: 1.1rem;
        padding-left: .8em;
    }

}


@media (min-width: 576px) and (max-width: 767.98px) {
    .BenefitCard__main-container{
        /* border: 5px solid blue; */
        max-width: 25em;
        border-radius: 1.25rem;
        background: #f5f1fd;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        /* padding: .5em; */
        padding-left: .5em;
        padding-right: .5em;
    }    
    .BenefitCard__inner-container{
        padding: 1em;
    }
    .BenefitCard__media-container{
        display: flex;
        flex-direction: row;
    }
    .BenefitCard__description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* border: 5px solid rgb(0, 0, 0); */
    }

    .BenefitCard__about-benefit{
        padding: 1em;
    }
    .BenefitCard__benefit p {
        text-align: justify;
        font-size: 1.2rem;
        line-height: 1.5rem;
    }
    .BenefitCard__about-information{
        padding: 1em;
        /* border: 3px solid red; */
    }
    .BenefitCard__information p {
        text-align: justify;
        font-size: 1.4rem;
        line-height: 1.9rem;
    }

    .BenefitCard__description-container div p{
        font-size: 1.1rem;
        padding-left: .8em;
    }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .BenefitCard__main-container{
        /* border: 5px solid blue; */
        max-width: 25em;
        border-radius: 1.25rem;
        background: #f5f1fd;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: .5em;
       margin-left: .5em;
     margin-right: .5em;
    }    
    .BenefitCard__inner-container{
        padding: 1em;
    }
    .BenefitCard__media-container{
        display: flex;
        flex-direction: row;
    }
    .BenefitCard__description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* border: 5px solid rgb(0, 0, 0); */
    }

    .BenefitCard__about-benefit{
        padding: 1em;
    }
    .BenefitCard__benefit p {
        text-align: justify;
        font-size: 1.2rem;
        line-height: 1.5rem;
    }
    .BenefitCard__about-information{
        padding: 1em;
        /* border: 3px solid red; */
    }
    .BenefitCard__information p {
        text-align: justify;
        font-size: 1.4rem;
        line-height: 1.9rem;
    }

    .BenefitCard__description-container div p{
        font-size: 1.1rem;
        padding-left: .8em;
    }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .BenefitCard__main-container{
        /* border: 5px solid blue; */
        max-width: 28em;
        border-radius: 1.25rem;
        background: #f5f1fd;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: .5em;
        margin-left: .5em;
        margin-right: .5em;
    }    
    .BenefitCard__inner-container{
        padding: 1em;
    }
    .BenefitCard__media-container{
        display: flex;
        flex-direction: row;
    }
    .BenefitCard__description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* border: 5px solid rgb(0, 0, 0); */
    }

    .BenefitCard__about-benefit{
        padding: 1em;
    }
    .BenefitCard__benefit p {
        text-align: justify;
        font-size: 1.2rem;
        line-height: 1.5rem;
    }
    .BenefitCard__about-information{
        padding: 1em;
        /* border: 3px solid red; */
    }
    .BenefitCard__information p {
        text-align: justify;
        font-size: 1.4rem;
        line-height: 1.9rem;
    }

    .BenefitCard__description-container div p{
        font-size: 1.1rem;
        padding-left: .8em;
    }
}

@media (min-width: 1200px) {
    .BenefitCard__main-container{
        /* border: 5px solid blue; */
        max-width: 28em;
        border-radius: 1.25rem;
        background: #f5f1fd;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: .5em;
        margin-left: .5em;
        margin-right: .5em;
    }    
    .BenefitCard__inner-container{
        padding: 1em;
    }
    .BenefitCard__media-container{
        display: flex;
        flex-direction: row;
    }
    .BenefitCard__description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* border: 5px solid rgb(0, 0, 0); */
    }

    .BenefitCard__about-benefit{
        padding: 1em;
    }
    .BenefitCard__benefit p {
        text-align: justify;
        font-size: 1.2rem;
        line-height: 1.5rem;
    }
    .BenefitCard__about-information{
        padding: 1em;
        /* border: 3px solid red; */
    }
    .BenefitCard__information p {
        text-align: justify;
        font-size: 1.4rem;
        line-height: 1.9rem;
    }

    .BenefitCard__description-container div p{
        font-size: 1.1rem;
        padding-left: .8em;
    }
}
