
.BannerSection__main-section-container{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 115vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-top: 0;
    padding-left: 2em;
    padding-bottom: 6em;
    margin-top: 6em;
    /* border: 3px solid red; */
    position: relative;
    
}

.BannerSection__onbg-img-container{
    /* border: 3px solid red; */
    position: absolute;
    right: 0;
}


/*============== CARD DESIGN ============*/

.BannerSection__main-card-info-container {
    display: inline-flex;
    padding: 3rem 3.125rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.625rem;
    background:  rgb(255, 255, 255) !important;
    /* border: 3px solid white; */
    /* margin-bottom: 10em;  */
    width: auto;
    height: 30em;
    padding-bottom: 10em;
    z-index: 1;

    
}

    .BannerSection__main-title-container {
        display: flex;
        width: 33.625rem;
        height: 12.1875rem;
        justify-content: flex-start;
        align-items: center;
        /* border: 3px solid green; */
    }
        .BannerSection__main-title-container h2 {
            color: var(--secundary-color);
            font-family: Roboto Mono, sans-serif;
            font-size: 2.5rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding-bottom: 2em;
        }


    .BannerSection__main-body-container{
        display: flex;
        width: 33.625rem;
        height: 10.8125rem;
        justify-content: flex-start;
        align-items: center;
        /* border: 3px solid green; */
        flex-shrink: 0;
    
    }
        .BannerSection__main-body-container p {
            color: #000000;
            font-family: 'Lato', sans-serif;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 300;
            line-height: 2.25rem;
            padding-top: 1em;

        }
        .CardInfo__important-text{
            color: #000000;
            font-family: 'Lato', sans-serif;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2.25rem;
            padding-top: 1em;
        }
            

    .BannerSection__main-button-container{
        display: flex;
        padding: 1.25rem 0.625rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.25rem;
        border-radius: 1.25rem;
        border: none;
        background:  var(--senary-color);
        
        
       
    }
        .BannerSection__inner-button-container{
            display: flex;
            width: 12.40713rem;
            height: 1.125rem;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            
            
        }

        .BannerSection__inner-button-container a{
            color: #FFF;
            text-align: center;
            font-family: 'Roboto Mono', sans-serif;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.5rem; /* 100% */
            text-transform: uppercase;
            text-decoration: none;
        }
        .BannerSection__inner-button-container a:hover{
            color: white;
        }

        .BannerSection__banner-title-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin-top: 3.3em;
        }
        
        .BannerSection__banner-title{
            font-size: 2.1rem;
            text-align: center;
            background-color: #FFF;
            padding: 1.5em 3em 2em 3em;
            border-radius: 9px;
            margin-bottom: 0;
            font-family: 'Karantina', sans-serif;
            color :var(--secundary-color);
        }



/*==================== RESPONSIVE =========================*/
@media (max-width: 415px){
    .BannerSection__main-card-info-container{
        /* border: 3px solid rgb(255, 0, 0) !important; */
        width: auto;
        margin: 0;
        margin-top: 3em;
        gap: 0.625rem;
        padding: 1.6em;
        align-items: center;
        height: 35em !important;
    }
    .CardInfo__important-text{
        font-size: 1.1rem;
        font-weight: 400;
    }
    .BannerSection__main-title-container h2 {
        font-size: 1.5rem !important;
    }
    .BannerSection__banner-title-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 3.3em;
    }
    
    .BannerSection__banner-title{
        font-size: 2.1rem;
        text-align: center;
        background-color: #FFF;
        padding: 1em;
        border-radius: 9px;
        margin-bottom: 0;
        font-family: 'Karantina', sans-serif;
        color :var(--secundary-color);
    }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .BannerSection__main-section-container{
        /* border: 6px solid blue; */
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 125vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        align-items: center;
        padding-top: 0;
        padding-right: 1em;
        padding-left: 2em;
        padding-bottom: 6em;
        margin-top: 1em;
    } 
    .BannerSection__main-card-info-container{
        /* border: 3px solid rgb(255, 255, 255); */
        width: auto;
        margin: 0;
        margin-top: 3em;
        gap: 0.625rem;
        padding: 1.6em;
        align-items: center;
        height: 30em;
   
    }
    .BannerSection__main-title-container{
        /* border: 2px solid blue; */
        width: auto; 
        margin: 0;
        padding: 0;   
    }
        .BannerSection__main-title-container h2 {
            color: var(--secundary-color);
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-size: 2rem;
            font-style: normal;
            font-weight: 500;
            text-align: left;
            margin: 0;
            padding: 0;
            
            /* line-height: normal; */
            
        }
        
        .BannerSection__main-body-container{
            /* border: 2px solid green; */
            width: auto;
            height: auto;
        }
            .BannerSection__main-body-container p {
                color: #000000;
                font-size: 1.1rem;
                font-style: normal;
                font-weight: 300;
                line-height: 2rem; /* 150% */
                margin-top: 1em;
            }
            .CardInfo__important-text{
                font-size: 1.1rem;
                font-weight: 400;
            }
              

            .BannerSection__inner-button-container a{
                font-size: 1.1rem;
                font-weight: 600;
                line-height: 1.5rem; /* 100% */
                color: white;
            }
            .BannerSection__main-button-container{
                margin-top: 1em;
            }


            .BannerSection__onbg-img-container{
                /*  border: 3px solid red; */
                 position: absolute;
                 right: 0;
                 left: 0;
                 top: 5em;
                 bottom: 9em;
             }
                 .BannerSection__onbg-img{
                    /*  border: 3px solid blue; */
                     width: 100%;
                     height: 95%;
                 }
                /*  .BannerSection__main-card-info-container {
                     background: rgba(255, 255, 255, 0.50);
                 } */
                 .BannerSection__onbg-img-container{
                     opacity: .5;
                 }
                 .BannerSection__banner-title-container{
                     width: auto;
                 }
                 .BannerSection__banner-title{
                     font-size: 2.1rem;
                 }

                 .BannerSection__banner-title{
                    font-size: 2.2rem;
                }
           
 }

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
    .BannerSection__main-section-container{
        /* border: 6px solid rgb(0, 0, 0); */
        flex-direction: column;

        justify-content: center;
        align-items: center;
        width: auto;
        
        padding-left: 1em;
        padding-right: 1em;
    } 
    .BannerSection__main-card-info-container{
       /*  border: 3px solid rgb(255, 3, 3); */
        width: auto;
        height: 32em;
        margin: 0;
        margin-top: 3em;
        gap: 0.625rem;
        padding: 2em;
        align-items: center;
     
        
        
        
    }
    .BannerSection__main-title-container{
        /* border: 2px solid blue; */
        width: 100%;
        margin: 0;
        padding: 0;
           
    }
        .BannerSection__main-title-container h2 {
            color: var(--secundary-color);
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: "Roboto Mono", sans-serif;
            font-size: 2.5rem;
            font-style: normal;
            font-weight: 800;
            text-align: left;
            padding: 0;
            /* border: 2px solid red; */
            margin: 0;
            
            /* line-height: normal; */
            
        }
        
        .BannerSection__main-body-container{
            /* border: 2px solid green; */
            width: auto;
            height: auto;
        }
            .BannerSection__main-body-container p {
                color: #000000;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 300;
                line-height: 2rem; /* 150% */
            }
               
                .BannerSection__main-button-container{
                    margin-top: 1em;
                  
                }
            .BannerSection__inner-button-container a{
                font-size: 1.1rem;
                font-weight: 600;
                line-height: 1.5rem; /* 100% */
            }



            .BannerSection__onbg-img-container{
                /* border: 3px solid red; */
                position: absolute;
                right: 0;
                left: 0;
                top: 5em;
                bottom: 2em;
            }
                .BannerSection__onbg-img{
                    /* border: 3px solid blue; */
                    width: 100%;
                    height: 100%;
                }
                .BannerSection__main-card-info-container {
                    background: rgba(255, 255, 255, 0.50);
                }
                .BannerSection__onbg-img-container{
                    opacity: .6;
                }

                .BannerSection__banner-title{
                    font-size: 3.2rem;
                }
 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .BannerSection__main-section-container{
        /* border: 6px solid rgb(0, 0, 0); */
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: auto;
        margin: 0;
    } 
    .BannerSection__main-card-info-container{
        /* border: 3px solid rgb(255, 255, 255); */
        width: auto;
        height: 30em;
        margin: 0;
        margin-top: 3em;
        gap: 0.625rem;
        padding: 1.6em;
        align-items: center;
    }
    .BannerSection__main-title-container{
        /* border: 2px solid blue; */
        margin: 0;
        padding: 0; 
    }
    .BannerSection__main-title-container h2 {
        color: var(--secundary-color);
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: "Roboto Mono", sans-serif;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 800;
        text-align: left;
        padding: 0;
        /* border: 2px solid red; */
        margin: 0;
        
        /* line-height: normal; */
        
    }
    .BannerSection__main-body-container{
        /* border: 2px solid green; */
        padding: 0;
        margin: 0;
               
    }
    .BannerSection__main-body-container p {
        color: #000000;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 300;
        line-height: 2.1rem; /* 150% */
        /* border: 3px solid red; */
        margin: 0;
        padding: 0;
    }
    .BannerSection__main-button-container{
        margin-bottom: 1em;
    }

    .BannerSection__main-card-info-container {
        background: rgba(255, 255, 255, 0.50);
    }
    .BannerSection__onbg-img-container{
        opacity: .8;
    }

    
    .BannerSection__banner-title{
        font-size: 3rem;
        padding: 1em;
    }
    

 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .BannerSection__main-card-info-container{

        width: 40em;
        height: 30em;
        margin: 0;
        margin-top: 3em;
        gap: 0.625rem;
        padding: 1.6em;
        align-items: center;
    }
    .BannerSection__main-title-container{
        /* border: 2px solid blue; */
        margin: 0;
        padding: 0; 
    }
    .BannerSection__main-title-container h2 {
        color: var(--secundary-color);
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: "Roboto Mono", sans-serif;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 800;
        text-align: left;
        padding: 0;
        margin: 0; 
    }
    .BannerSection__main-body-container p {
        color: #000000;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 300;
        line-height: 2.1rem; 
        margin: 0;
        padding: 0;
    }

    .BanneSection__main-btn-container{
        /* border: 3px solid red; */
        width: 100%;
    }
    .BannerSection__main-button-container{
        /* border: 3px solid green; */
        max-width: 40%;
        margin-left: 2em;
    }

    .BannerSection__main-card-info-container {
        background: rgba(255, 255, 255, 0.50);
    }
    .BannerSection__onbg-img-container{
        opacity: .9;
    }

    .BannerSection__banner-title{
        font-size: 3rem;
        padding: 1.3em;
    }
 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    
.BannerSection__main-section-container{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-top: 0;
    padding-left: 2em;
    padding-bottom: 6em;
    margin-top: 6em;
}
    .BannerSection__main-card-info-container{
        width: 40em;
        height: 30em;
        margin: 0;
        margin-top: 3em;
        gap: 0.625rem;
        padding: 1.6em;
        align-items: center;
    }
    .BannerSection__main-title-container{
        /* border: 2px solid blue; */
        margin: 0;
        padding: 0; 
    }
    .BannerSection__main-title-container h2 {
        color: var(--secundary-color);
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: "Roboto Mono", sans-serif;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 800;
        text-align: left;
        padding: 0;
        margin: 0; 
    }
    .BannerSection__main-body-container p {
        color: #000000;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 300;
        line-height: 2.1rem; 
        margin: 0;
        padding: 0;
    }
    .BanneSection__main-btn-container{
        /* border: 3px solid red; */
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .BannerSection__main-button-container{
        /* border: 3px solid green; */
        max-width: 40%;
        margin-left: 2.5em;
    }

    .BannerSection__onbg-img-container{
        opacity: 1;
    }

    .BannerSection__banner-title{
        font-size: 4rem;
        padding-left: 3em;
        padding-right: 3em;
        padding-top: 0.5em;
        padding-bottom: .5em;
    }
   
 }