.main-navigation__menu-btn {
    width: 4rem;
    height: 3rem;
    background: var(--senary-color);
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-right: 1.5rem;
    cursor: pointer;
    padding: .6em;
    border-radius: .4em .4em .4em .4em;
  }
  
  .main-navigation__menu-btn span {
    display: block;
    width: 3rem;
    height: 2.5px;
    background: white;
  }
  
  .main-navigation__title {
    color: white;
    margin-left: .5em;
  }
  
  .main-navigation__title a {
    text-decoration: none;
    color: white;
  }
  
  .main-navigation__header-nav {
    display: none;
  }
  
  .main-navigation__drawer-nav {
    height: 100%;
  }
  
  .MainNavigation__main-social-media-container{
    width: 6em;
    margin-right: 0em;
    margin-left: 1em;
  } 
    .MainNavigation__inner-social-media-container{ 
        display: flex;
        flex-direction: row;
        justify-content: space-around;
  }
  
  .Navigation__logo{
    cursor: pointer;
  }
  
  
  
  /*================== FULL RESPONSIVE MODE ============*/
  
  @media (max-width: 575px ){
  .main-navigation__drawer-nav{
  }
    .main-navigation__menu-btn{
    }
      .main-navigation__title{
        margin-bottom: 0; 
        width: 15em !important; 
      } 
  }
  
  @media (max-width: 768px ){
      .main-navigation__title{
        width: 10em; 
        margin-bottom: 0; 

      }
        .Navigation__logo{
          width: 100%;
        }
  }
  
  
  
  @media (min-width: 768px) and  (max-width: 991.98px){ 
    .main-navigation__title {
      color: white;
      margin-left: 1.1em;
      width: 8em; 
      margin-bottom: 0;   
    }
    .Navigation__logo{
      width: 100%;
    } 
    .main-navigation__menu-btn {
      display: flex;
    }
  
    .main-navigation__header-nav {
      display: none;
  
    }
  
    .MainNavigation__main-social-media-container{
      width: 6em;
      margin-right: 2em;
    } 
      .MainNavigation__inner-social-media-container{ 
          display: flex;
          flex-direction: row;
          justify-content: space-around;
      }
  }
  
  
  @media (min-width: 992px) {
    .main-navigation__title {
      color: white;
      margin-left: 1.1em;
      width: 6em; 
      margin-bottom: 0;   
    }
    .Navigation__logo{
      width: 100%;
    }
    .main-navigation__menu-btn {
      display: none;
    }
  
    .main-navigation__header-nav {
      display: block;
    }
  
    .main-navigation__title {
      color: white;
      margin-left:0;
      padding: 0;
    }
  
  }
  
  
  @media (min-width: 1200px) {
    .main-navigation__title{
      margin-left: .6em;
      width: 5.5em;
    }
    .MainNavigation__main-social-media-container{
      margin-right: 1.2em;
    }
    .Navigation__logo{ 
      width: 100%;
    }
  }
  
  
  @media (min-width: 1400px) { 
  
  }
  